import axios from 'axios';

import { slpData } from 'models/VIEW/RequestAQuote';
import { MarketingDataResponse } from '../models/PEMS/PEMMarketingData';
import { OffersResponse, TFSContract } from '../models/PEMS/PEMOffers';
import { PEMCalculateResponse } from '../models/PEMS/PEMSCalculate';
import { Tab } from '../models/VIEW/ViewOffersTab';
import brandService from '../services/BrandService';

/* EFC */
export const getDealers = (zipcode: string) => {
  const brandId = brandService.getBrand('dis');
  const url = `${process.env.REACT_APP_DIS_BASE_URL}${process.env.REACT_APP_DIS_GET_DEALERS}`;
  return axios.get(url, {
    headers: {
      'x-api-key': process.env.REACT_APP_DIS_API_KEY ?? '',
    },
    params: {
      brandId,
      zipcode,
      resultsMax: 1,
      searchMode: 'pmaProximityLayered',
      resultsMode: 'full',
      resultsFormat: 'json',
    },
  });
};

/* VAPI */
export const getVapiTrims = () => {
  const url = `${process.env.REACT_APP_VAPI_BASE_URL}/trims/toyota/NATIONAL/EN`;
  return axios.get(url, {
    headers: {
      'x-api-key': process.env.REACT_APP_VAPI_API_KEY,
    },
  });
};

export const getVapiSeries = () => {
  const url = `${process.env.REACT_APP_VAPI_BASE_URL}/availableSeries/toyota/NATIONAL/EN`;
  return axios.get(url, {
    headers: {
      'x-api-key': process.env.REACT_APP_VAPI_API_KEY,
    },
  });
};

export const getVehicleData = (vehicles: string) => {
  const url = `${process.env.REACT_APP_PE_SIT_WS_BASE_URL}/getVehiclesDetails`;
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      vehicles,
    },
  });
};

/* VCR */

export type VCRJelliesRequest = {
  vehicles: Array<{
    year: string;
    series: string;
    trimIds: string[];
  }>;
};
export const getVCRJellies = (jelliesRequest: VCRJelliesRequest) => {
  const url = `${process.env.REACT_APP_PE_SIT_WS_BASE_URL}/getVehiclesJellies`;
  return axios.post(url, jelliesRequest);
};

/* PEMS */
type GetOffers = {
  year: string;
  state: string;
  series: string;
  region: string;
  tdaCode: string;
};
export const getOffers = ({
  region,
  state,
  series,
  year,
  tdaCode,
}: GetOffers): Promise<{ data: OffersResponse }> => {
  const BRAND = brandService.getBrand('efc');
  const url = `${process.env.REACT_APP_PEMS_BASE_URL}${process.env.REACT_APP_PEMS_GET_OFFERS}/${region}`;
  return axios.get(url, {
    headers: {
      'x-api-key': process.env.REACT_APP_PEMS_KEY ?? '',
    },
    params: {
      clientId: process.env.REACT_APP_PEMS_CLIENT_ID,
      year,
      state,
      series,
      brand: BRAND,
      tdaCode: tdaCode.replace('10', ''),
    },
  });
};

type GetMarketingData = {
  lang: string;
  zipCode: string;
  offerId: string;
};

export const getMarketingData = ({
  zipCode,
  offerId,
  lang,
}: GetMarketingData): Promise<{ data: MarketingDataResponse[] }> => {
  const BRAND = brandService.getBrand('dis');
  const url = `${process.env.REACT_APP_PEMS_BASE_URL}${process.env.REACT_APP_PEMS_GET_MARKETING_DATA}/${zipCode}`;
  return axios.get(url, {
    headers: {
      'x-api-key': process.env.REACT_APP_PEMS_KEY ?? '',
    },
    params: {
      clientId: process.env.REACT_APP_PEMS_CLIENT_ID,
      offerId,
      lang,
      brandId: BRAND,
    },
  });
};

export type OfferRequestParam = {
  type: string;
  rate?: number;
  term?: number;
  tier?: string;
  name?: string;
  isBase: boolean;
  amount?: number;
  offerId?: string;
  mileage?: number;
  subCash?: number;
  highTerm?: number;
  offerType: string;
  modelCode?: string;
  advertised: boolean;
  amountDown?: number;
  dealerGross?: number;
  downPayment?: number;
  dueAtSigning?: number;
  targetPayment?: number;
  isRateOffer?: boolean;
  isSubvented?: boolean;
  offerAmount?: number;
  compatibility: string[];
  grossCapCost?: number;
  advertisedTrim?: string; // same as modelCode
  tfsContractList?: TFSContract[];
  offerAmountDown?: number;
  additionalCashId?: number;
  advertisedVinMsrp?: number; // lease offers configured with specific msrp in mind
  acquisitionFeeCapitalized: boolean;
  acquisitionFee?: number;
  maxAmountPerMonth?: number;
};

export type PEMSCalculateRequestData = {
  acquisitionFee: number;
  acquisitionFeeCapitalized: boolean;
  baseMsrp: number; // baseMsrp + dest fee
  downPayment: number | null;
  mileage: number | null;
  model: string;
  modelYear: string;
  msrp?: number; // msrp query string if present
  offers: OfferRequestParam[];
  pricingArea: string;
  state: string;
  term: string;
  tier: string;
  tradeIn: number;
  trimCode: string;
  type: Tab;
};
export const getPayments = ({
  regionCode,
  data,
}: {
  regionCode: string;
  data: PEMSCalculateRequestData[];
}): Promise<{ data: PEMCalculateResponse }> => {
  const url = `${process.env.REACT_APP_PEMS_BASE_URL}${process.env.REACT_APP_PEMS_GET_CALCULATIONS}/${regionCode}`;
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_PEMS_KEY ?? '',
    },
    params: {
      clientId: process.env.REACT_APP_PEMS_CLIENT_ID,
    },
  });
};

export const getPEQuote = (data: slpData) => {
  const url = `${process.env.REACT_APP_SLP_API}/leads`;
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_SLP_API_KEY}`,
    },
  });
};

export const getDealersContactForm = async (inputZipCode: string) => {
  const brandId = brandService.getBrand('dis');
  const url = `${process.env.REACT_APP_DIS_BASE_URL}${process.env.REACT_APP_DIS_GET_DEALERS}`;

  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_DIS_API_KEY ?? '',
    },
    params: {
      brandId,
      zipcode: inputZipCode,
      resultsMax: 25,
      searchMode: 'pmaProximityLayered',
      resultsMode: 'starOnly',
      proximityMode: 'expandingRadius',
      resultsFormat: 'json',
    },
  };
  const pmaRespA = axios.get(url, options);
  const proximityRespA = axios.get(url, {
    ...options,
    params: {
      ...options.params,
      searchMode: 'proximityOnly',
    },
  });
  const [pmaResp, proximityResp] = await Promise.all([
    pmaRespA,
    proximityRespA,
  ]);
  return [pmaResp, proximityResp];
};

export const getMycoFile = async () => {
  const url = `${process.env.REACT_APP_JSDS_BASE_URL}/BATVD/clsseriesmodels.json`;
  let modelsResp;

  if (
    process.env.REACT_APP_MYCO_USERNAME &&
    process.env.REACT_APP_MYCO_PASSWORD
  ) {
    const options = {
      auth: {
        username: process.env.REACT_APP_MYCO_USERNAME,
        password: process.env.REACT_APP_MYCO_PASSWORD,
      },
    };
    modelsResp = await axios.get(url, options);
  } else {
    modelsResp = await axios.get(url);
  }

  return modelsResp.data;
};
